.grid {
  display: flex;
  margin: calc(var(--grid-gutter) * -1) 0 0 calc(var(--grid-gutter) * -1);
  flex-wrap: wrap;

  &-verticalItemsCenter {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;

    }
  }

  &-reverse {
    flex-flow: row-reverse;
  }

  // Breakpoint
  @media (max-width: 767px) {
    display: block;
    flex-flow: row; // Revert any inverted flow
  }
}

.gridItem {
  flex: 1;
  padding: calc(var(--grid-gutter) * 1) 0 0 calc(var(--grid-gutter) * 1);

  &-verticalCenter {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }

  &-horizontalCenter {
    display: flex;
    justify-content: center;
  }
}
