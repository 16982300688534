.flex {
  display: flex;
  flex-direction: row;
}

// Modifiers
.-align-center {
  align-items: center;
}
.-align-start {
  align-items: flex-start;
}
.-align-end {
  align-items: flex-end;
}

.-justify-center {
  justify-content: center;
}
.-justify-spaceBetween {
  justify-content: space-between;
}
.-justify-spaceAround {
  justify-content: space-around;
}
.-justify-start {
  justify-content: flex-start;
}
.-justify-end {
  justify-content: flex-end;
}

.-wrap {
  flex-wrap: wrap;
}
