/* Spinner.module.scss */

.spinner {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  top: -8px;
  left: 8px;

  &::after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 12px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: spinner 1.2s infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

  /* Additional color variations */
  &.spinner-color-green {
    &::after {
      border-color: var(--color-green-40) transparent var(--color-green-40) transparent;
    }
  }
}
