$largeBreakpoint: 1079px;
$mediumBreakpoint: 767px;
$smallBreakpoint: 600px;

.outer {
  // Default horisontal padding
  padding: 1px var(--page-gutter); // 1px = fix collapsing margins

  // Vertical margins, padding
  &-size-none {
    margin: 0;
  }

  &-size-noHorizontalPadding {
    padding: 0;
  }

  &-size-xsmall {
    margin: var(--spacing-3) 0;

    @media (max-width: $smallBreakpoint) {
      margin: var(--spacing-3) 0;
    }
  }

  &-size-small {
    margin: var(--spacing-5) 0;

    @media (max-width: $mediumBreakpoint) {
      margin: var(--spacing-5) 0;
    }
  }

  &-size-medium {
    margin: var(--spacing-10) 0;

    @media (max-width: $mediumBreakpoint) {
      margin: var(--spacing-6) 0;
    }
  }


  &-size-firstSection {
    margin: var(--spacing-7) 0;

    @media (max-width: $mediumBreakpoint) {
      margin: var(--spacing-2) 0 var(--spacing-7);
    }
  }

  &-size-large {
    margin: var(--spacing-10) 0;

    @media (max-width: $mediumBreakpoint) {
      margin: var(--spacing-8) 0;
    }
  }

  // Background colors
  &-color-peach-40 {
    background-color: var(--color-peach-40);
  }

  &-color-peach-30 {
    background-color: var(--color-peach-30);
  }

  &-color-peach-20 {
    background-color: var(--color-peach-20);
  }

  &-color-yellow-40 {
    background-color: var(--color-yellow-40);
  }

  &-color-yellow-30 {
    background-color: var(--color-yellow-30);
  }

  &-color-cream-40 {
    background-color: var(--color-cream-40);
  }

  &-color-red-40 {
    background-color: var(--color-red-40);
    color: var(--color-cream-40);
  }

  &-color-green-50 {
    background-color: var(--color-green-50);
  }

  &-color-green-40 {
    background-color: var(--color-green-40);
  }

  &-color-green-30 {
    background-color: var(--color-green-30);
  }

  &-color-green-20 {
    background-color: var(--color-green-20);
  }

  &-no-limit {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }
}

.inner {
  margin: 0 auto;
  max-width: var(--page-width-max);

  // Vertical padding
  &-size-small {
    padding: var(--spacing-6) 0;
  }

  &-size-medium {
    padding: var(--spacing-8) 0;
  }

  &-size-large {
    padding: var(--spacing-10) 0;
  }

  &-size-footer {
    padding: var(--spacing-11) 0 var(--spacing-9);
    @media (max-width: $mediumBreakpoint) {
      padding: var(--spacing-10) 0 var(--spacing-8);
    }
  }

  // Limit width
  &-limited-width {
    max-width: var(--page-width-limited);
    margin: 0 auto; // Centered horisontally
  }

  // Limit width to medium
  &-limited-width-small {
    max-width: var(--page-width-limited-small);
    margin: 0 auto;
  }

  &-limited-width-xsmall {
    max-width: var(--page-width-limited-xsmall);
    margin: 0 auto; // Centered horisontally
  }

  &-no-limit {
    max-width: none;
  }

  // Center text
  &-center {
    text-align: center;
  }

  &-border {
    border: 1px solid var(--color-red-30);
    border-radius: var(--spacing-3);
    padding: var(--spacing-5);
  }
}
