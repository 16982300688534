.button {
  border: 3px solid var(--color-green-40);
  border-radius: var(--border-radius);
  background-color: var(--color-peach-40);
  color: var(--color-green-40);
  display: inline-block;
  padding: var(--spacing-3) var(--spacing-7);
  text-decoration: none;
  transition: var(--transition-quick);
  cursor: pointer;
  text-align: center;
  font-family: "Walsheim-medium", sans-serif;

  &-primary {
    background-color: var(--color-green-40);
    color: var(--color-cream-40);
    text-decoration: none !important;

    &:hover,
    &:disabled {
      background-color: var(--color-green-30);
      border-color: var(--color-green-30);
    }
  }

  &-secondary {
    background-color: var(--color-cream-40);
    color: var(--color-green-40) !important;
    text-decoration: none !important;
    font-family: "Walsheim-medium", sans-serif;

    &:hover,
    &:disabled {
      background-color: var(--color-green-40);
      border-color: var(--color-green-40);
      color: var(--color-cream-40) !important;
    }
  }

  &-menu {
    background-color: var(--color-green-40);
    color: var(--color-cream-40);
    border-color: var(--color-cream-40);
    padding: var(--spacing-2) var(--spacing-6);

    &:hover,
    &:disabled {
      background-color: var(--color-green-30);
    }
  }

  &-mini {
    @media screen and (max-width: 450px) {
      padding: var(--spacing-2) var(--spacing-4);
    }
  }

  &-color-red {
    background-color: var(--color-red-40);
    border-color: var(--color-cream-40);
    color: var(--color-cream-40) !important;
    text-decoration: none !important;

    &:hover,
    &:disabled {
      background-color: var(--color-red-30);
      border-color: var(--color-peach-30);
    }
  }
}

.color-peach {
  background-color: var(--color-peach-40);
  border-color: var(--color-peach-40);
  color: var(--color-black-40) !important;
  text-decoration: none !important;

  &:hover,
  &:disabled {
    background-color: var(--color-red-30);
    border-color: var(--color-peach-30);
  }
}

.error-shake {
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}
