@import "utils/fluidtype";

@font-face {
  font-family: "Walsheim";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/GT-Walsheim-Pro-Regular.woff2") format("woff2"),
  url("/fonts/GT-Walsheim-Pro-Regular.woff") format("woff");
}

@font-face {
  font-family: "Walsheim-medium";
  font-style: normal;
  font-weight: bold;
  src: url("/fonts/GT-Walsheim-Pro-Medium.woff2") format("woff2"),
  url("/fonts/GT-Walsheim-Pro-Medium.woff") format("woff");
}

@font-face {
  font-family: "Walsheim-bold";
  font-style: normal;
  src: url("/fonts/GT-Walsheim-Bold.woff2") format("woff2"),
  url("/fonts/GT-Walsheim-Bold.woff") format("woff");
}

// Fluid typography
$minWidth: 320px;
$maxWidth: 1440px;

body {
  font-family: "Walsheim", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  word-spacing: 1px;
  @include fluid-type($minWidth, 1000px, 18px, 20px);
}

h1,
.h1,
.text-large {
  @include fluid-type($minWidth, $maxWidth, 28px, 48px);
  font-family: "Walsheim-medium", sans-serif;
  line-height: 1.2;
}

h2,
.h2,
.text-medium {
  @include fluid-type($minWidth, $maxWidth, 24px, 36px);
  font-family: "Walsheim-medium", sans-serif;
  line-height: 1.48;
}

h3,
.h3 {
  @include fluid-type($minWidth, 1000px, 20px, 28px);
  font-family: "Walsheim-medium", sans-serif;
  line-height: 1.48;
}

h4,
.h4 {
  @include fluid-type($minWidth, 1000px, 18px, 24px);
  font-family: "Walsheim-bold", sans-serif;
  line-height: 1.2;
}

h5,
.h5 {
  @include fluid-type($minWidth, 1000px, 18px, 24px);
  font-family: "Walsheim-medium", sans-serif;
  line-height: 1.2;
}

.body-quote {
  @include fluid-type($minWidth, 1000px, 18px, 24px);
  font-family: "Walsheim", sans-serif;
  line-height: 1.48;
}

.body-text {
  @include fluid-type($minWidth, 1000px, 18px, 20px);
  font-family: "Walsheim", sans-serif;
  line-height: 1.6;
}

.text-caption {
  @include fluid-type($minWidth, 1000px, 16px, 16px);
  font-family: "Walsheim", sans-serif;
  line-height: 1.3;
}


.bold {
  font-weight: bold;
}

.highlight {
  color: var(--color-red-40);
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}

em {
  font-style: italic;
}

del {
  @include fluid-type($minWidth, 1000px, 20px, 26px);
  padding-right: 10px;
  text-decoration: none;
}

.font-family-medium {
  font-family: "Walsheim-medium", sans-serif;
}

