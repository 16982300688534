// Default flow
// https://24ways.org/2018/managing-flow-and-rhythm-with-css-custom-properties/

$flowSpace: 1em;

.flow > * + * {
  margin-top: $flowSpace;
}

// Needed for Sanity CMS block content which is wrapped in an extra div
.flowBlockContent > * > * + * {
  margin-top: $flowSpace;
}
