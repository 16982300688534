// Globals

html {
  scroll-behavior: smooth;
}

body {
  background: var(--color-cream-40);
  color: var(--color-black-40);
  min-width: 320px;
  vertical-align: baseline;
  position: relative;
}

video {
  object-fit: cover !important;
}

// Links
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.link {
  color: var(--color-red-40);
  text-decoration: underline;
  cursor: pointer;
  font-family: "Walsheim-medium", sans-serif;

  &:hover {
    text-decoration: none;
  }
}

.link-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.ul-list {
  list-style-type: disc;
  padding-left: 40px;
}

.ol-list {
  list-style-type: decimal;
}

// SVG
svg {
  max-width: 100%;
  height: auto;
}

// Helpers
.color--red {
  color: var(--color-red-40);
}

.color--green-40 {
  color: var(--color-green-40);
}

.color--black-40 {
  color: var(--color-black-40);
}

// Hack to remove flow margin
.no-margin-top {
  margin-top: 0 !important;
}

// Border radius
.border-radius-1 {
  border-radius: var(--spacing-1);
}

.border-radius-2 {
  border-radius: var(--spacing-2);
}

.border-radius-3 {
  border-radius: var(--spacing-3);
}

// Divider
hr {
  height: 2px;
  background: var(--color-peach-40);
}

.box-shadow {
  box-shadow: 0px 2px 20px rgba(55, 55, 55, 0.08);
  border-radius: var(--spacing-3);
  padding: var(--spacing-6) calc(var(--spacing-8) + var(--spacing-8));
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 600px) {
    border-radius: 0px;
    padding: 0px;
    box-shadow: none;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

// Animations
@keyframes animate-in-from-bottom {
  0% {
    transform: translateY(15px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animate-in-from-top {
  0% {
    transform: translateY(-15px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

// Animation helpers

[data-animate-in] {
  animation: animate-in-from-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

[data-animate-down] {
  animation: animate-in-from-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

[data-animation-order="1"] {
  animation-delay: 0.1s;
}

[data-animation-order="2"] {
  animation-delay: 0.15s;
}

[data-animation-order="3"] {
  animation-delay: 0.2s;
}

[data-animation-order="4"] {
  animation-delay: 0.25s;
}

[data-animation-order="5"] {
  animation-delay: 0.3s;
}

[data-animation-order="6"] {
  animation-delay: 0.35s;
}

[data-animation-order="7"] {
  animation-delay: 0.4s;
}

[data-animation-order="8"] {
  animation-delay: 0.45s;
}

[data-animation-order="9"] {
  animation-delay: 0.5s;
}
