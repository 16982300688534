$breakpointUp: 840px;
$breakpointDown: 840px;
$breakpointBigScreen: 1750px;

.header {
  padding: 1px 0 var(--spacing-2) 0; // Needed to give space for section margins
  background: var(--color-cream-40);
  line-height: 0.7; // Trying to align bottom of links and logo
  height: auto;

  // Sticky
  position: sticky;
  top: 0;
  z-index: 9;

  //box-shadow: 0 -13px 15px var(--color-black-40);

  @media (max-width: $breakpointDown) {
    display: none;
  }
}

.containerLogoMobileNavigation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logoMobileNavigation {
  width: 120px;
  margin-bottom: var(--spacing-4);
}

.navListItem {
  display: inline-block;

  // Hover underline
  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--color-green-40);
    text-decoration-thickness: 2px;
    text-underline-offset: 8px;
  }

  &-selected {
    text-decoration: underline;
    text-decoration-color: var(--color-green-40);
    text-decoration-thickness: 2px;
    text-underline-offset: 8px;
  }
}

.underline {
  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--color-green-40);
    text-decoration-thickness: 2px;
    text-underline-offset: 6px;
  }
}

// Mobile menu

.headerMobileWrap {
  padding: 16px var(--page-gutter);
  background: var(--color-cream-40);

  // Sticky
  position: sticky;
  top: 0;
  z-index: 9;

  @media (min-width: $breakpointUp) {
    display: none;
  }
}

.headerMobile {
  background: var(--color-red-40);
  color: var(--color-cream-40);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  text-align: center;
  padding: var(--spacing-6) var(--page-gutter);
  font-size: 22px;
  line-height: 2;
}

.logoWrapMobile {
  display: block;
  width: 80px;
  line-height: 1;
}

.logoWrapMobileAuth {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logoWrapMobileBar {
  display: inline-block;
  width: 80px;
}

.mobileFlex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;
}

.mobileFlexAuth {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.some a {
  margin-right: var(--spacing-4);

  &:hover {
    opacity: 0.8;
  }
}

.menuButton {
  position: absolute;
  right: var(--page-gutter);
  z-index: 99;
}

.menuButtonText {
  color: var(--color-black-40);
  margin-right: 12px;
  display: inline-block;
  font-weight: bold;
}

.linksContainer {
  margin-top: var(--spacing-6);
  text-align: right;
}
