// Global variables
:root {
  // Colors
  --color-cream-40: #fffcf4;
  --color-black-40: #373737;
  --color-red-40: #f45338;
  --color-red-30: #f6755f;
  --color-peach-40: #FFD7C0;
  --color-peach-30: #ffeee5;
  --color-peach-20: #FAF3E9;
  --color-green-50: #50755C;
  --color-green-40: #5B8D6C;
  --color-green-30: #88C382;
  --color-green-20: #E1F9CE;
  --color-yellow-40: #ffd74b;
  --color-yellow-30: #FAC384;
  --color-grey-40: #767676;
  --color-grey-30: #CFCFCF;

  // Spacingc
  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 24px;
  --spacing-6: 32px;
  --spacing-7: 40px;
  --spacing-8: 56px;
  --spacing-9: 64px;
  --spacing-10: 80px;
  --spacing-11: 120px;

  // Animations
  --transition: 0.2s cubic-bezier(0, 0, 0.3, 0.9);
  --transition-quick: 0.1s cubic-bezier(0, 0, 0.3, 0.9);

  // Settings
  --page-width-max: 1340px;
  --page-width-limited: 1100px;
  --page-width-limited-small: 700px;
  --page-width-limited-xsmall: 500px;
  --page-gutter: 40px;
  --grid-gutter: 60px;
  --border-radius: 30px;

  // Screen types
  --screen-mobile: 320px;
  --screen-tablet: 768px;
  --screen-desktop: 1024px;
  --screen-big: 1750px;

  @media (max-width: 600px) {
    --page-gutter: 16px;
  }
}
